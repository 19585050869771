<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  column: {
    type: String,
    default: ''
  }
})

const valueColumn = computed(() => {
  return props.data[props.column] || 'NA'
})

</script>

<template>
  <span :class="{
    'text-yellow-800 opacity-50': valueColumn === 'NA'
  }">{{ valueColumn }}</span>
</template>

<style scoped>

</style>
