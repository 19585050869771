import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_FACTURACION_CAPITA

export default class OvsFacturasCapitaValorService {
  // Obtener el listado de facturas capita con valor
  getListOvsFacturasCapitaValor (params) {
    return http.get(`${baseUrl}/ovs/listar-ovs`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
