<script setup>
import { useFiltersOvsFacturaCapitaStore } from '@/apps/pharmasan/facturacion/capita/stores/filtersOvsFacturaCapita.store'
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

const filtersOvsFacturaCapitaStore = useFiltersOvsFacturaCapitaStore()
const ovsSeleccionadas = ref('')
const facturasSeleccionadas = ref('')
const {
  _filters
} = storeToRefs(filtersOvsFacturaCapitaStore)
const listTipoFecha = ref([
  { id: 'FECHA_CREACION', name: 'Fecha Creación' },
  { id: 'FECHA_ACTUALIZACION', name: 'Fecha Actualización' }
])

/**
 * Función para validar la entrada de teclado en el campo de OVs.
 * Evita la acción predeterminada al presionar Enter para prevenir saltos de línea.
 * Permite las teclas funcionales específicas definidas en functionalKeys.
 * Evita la entrada de caracteres no numéricos, espacios o comas.
 * @param {KeyboardEvent} e - Evento de teclado.
 */
const validarOvs = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault()
    guardarOvs()
  }

  const functionalKeys = [8, 9, 13, 16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 46, 67, 86]
  if (functionalKeys.includes(e.keyCode)) {
    return
  }

  if (/[^0-9\s,]/.test(e.key)) {
    e.preventDefault()
  }
}

/**
 * Función para validar el contenido al pegar texto.
 * Obtiene los datos del portapapeles y verifica si contiene caracteres no permitidos.
 * Evita el pegado si se encuentra algún carácter no permitido.
 * @param {Event} e - Evento de pegado.
 */
const validarPegado = (e) => {
  const clipboardData = e.clipboardData.getData('text')
  console.log('clipboardData', clipboardData)
  if (/[^0-9\s,]/.test(clipboardData)) {
    e.preventDefault()
  }
}

/**
 * Función para procesar y guardar las Ovs seleccionadas.
 * Divide la cadena de facturas por comas y espacios, filtra duplicados y valores vacíos,
 * y los agrega al array de facturas en el filtro.
 * Finalmente, reinicia la cadena de facturas seleccionadas.
 */
const guardarOvs = () => {
  if (!ovsSeleccionadas.value) {
    return
  }

  for (const ov of ovsSeleccionadas.value.split(/[,\s]+/)) {
    if (!_filters.value.numOvs.some((f) => f === ov) && ov !== '') {
      _filters.value.numOvs.push(ov)
    }
  }

  ovsSeleccionadas.value = ''
}

/**
 * Función para eliminar una ov del array de ovs en el filtro.
 * @param {string} ov - Número de OV a eliminar.
 */
const eliminarOv = (ov) => {
  const index = _filters.value.numOvs.findIndex((f) => f === ov)
  _filters.value.numOvs.splice(index, 1)
}

/**
 * Función para validar la entrada de teclado en el campo de facturas.
 * Evita la acción predeterminada al presionar Enter para prevenir saltos de línea.
 * Permite las teclas funcionales específicas definidas en functionalKeys.
 * Evita la entrada de caracteres no numéricos, espacios o comas.
 * @param {KeyboardEvent} e - Evento de teclado.
 */
const validarFacturas = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault()
    guardarFacturas()
  }

  const functionalKeys = [8, 9, 13, 16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 46, 67, 86]
  if (functionalKeys.includes(e.keyCode)) {
    return
  }

  if (/[^0-9\s,]/.test(e.key)) {
    e.preventDefault()
  }
}

/**
 * Función para procesar y guardar las Ovs seleccionadas.
 * Divide la cadena de facturas por comas y espacios, filtra duplicados y valores vacíos,
 * y los agrega al array de facturas en el filtro.
 * Finalmente, reinicia la cadena de facturas seleccionadas.
 */
const guardarFacturas = () => {
  if (!facturasSeleccionadas.value) {
    return
  }

  for (const factura of facturasSeleccionadas.value.split(/[,\s]+/)) {
    if (!_filters.value.numFacturas.some((f) => f === factura) && factura !== '') {
      _filters.value.numFacturas.push(factura)
    }
  }

  facturasSeleccionadas.value = ''
}

/**
 * Función para eliminar una factura del array de facturas en el filtro.
 * @param {string} factura - Número de factura a eliminar.
 */
const eliminarFactura = (factura) => {
  const index = _filters.value.numFacturas.findIndex((f) => f === factura)
  _filters.value.numFacturas.splice(index, 1)
}

/**
 * Función para validar la fecha final.
 * Verifica si la fecha final es menor a la fecha inicial.
 * Si es así, muestra un mensaje de error y retorna true.
 * @param {string} fechaInicial - Fecha inicial.
 * @param {string} fechaFinal - Fecha final.
 * @returns {boolean} - Retorna true si la fecha final es menor a la fecha inicial.
 */
const validateFechaFinal = (fechaInicial, fechaFinal) => {
  const fecIni = dayjs(fechaInicial)
  const fecFin = dayjs(fechaFinal)
  if (fecFin.isBefore(fecIni)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'La fecha final no puede ser menor a la fecha inicial'
    })
    return true
  } else {
    return false
  }
}

// Realizar la búsqueda y setear los filtros en el store
const updateFilter = () => {
  if (validateFechaFinal(_filters.value.fechaInicial, _filters.value.fechaFinal)) {
    return
  }
  filtersOvsFacturaCapitaStore.setRefrescarListadoOvs(true)
}

/**
 * Función para limpiar los filtros.
 * Reinicia los valores de los filtros a su estado inicial.
 */
const resetClear = () => {
  // Limpiar los filtros
  for (const [key] of Object.entries(_filters.value)) {
    if (key === 'numFacturas' || key === 'numOvs') {
      _filters.value[key] = []
    } else {
      _filters.value[key] = ''
    }
  }

  filtersOvsFacturaCapitaStore.setUpdateFilters(_filters.value)
  filtersOvsFacturaCapitaStore.setLimpiarListadoOvs(true)
}

const reload = () => {
  filtersOvsFacturaCapitaStore.setRefrescarListadoOvs(true)
}

// Watch para los cambios en los filtros
watch(_filters.value, (newValue, oldValue) => {
  filtersOvsFacturaCapitaStore.setUpdateFilters(newValue)
})

// Si se selecciona el tipo de fecha se habilita el filtro de fecha inicial con el primer día del mes actual y el filtro de fecha final con el último día del mes actual
watch(() => _filters.value.tipoFecha, (value) => {
  if (value) {
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    _filters.value.fechaInicial = firstDay.toISOString().slice(0, 10)
    _filters.value.fechaFinal = lastDay.toISOString().slice(0, 10)
  } else {
    _filters.value.fechaInicial = ''
    _filters.value.fechaFinal = ''
  }
})
</script>

<template>
  <div class="grid grid-cols-12 gap-x-4 gap-y-1">

    <!-- Numero de Ovs -->
    <div class="col-span-12 sm:col-span-6">
      <label for="numOvs" class="block text-xs font-medium text-gray-700 mb-2">Número de OVs:</label>
      <InputText
        id="numOvs"
        class="w-full text-xs"
        @keydown="validarOvs"
        @blur="guardarOvs"
        @paste="validarPegado"
        v-model="ovsSeleccionadas"
        placeholder="Ingrese número de OVs... (separados por coma o por espacio)"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px; width: 100%'
          }),
          input: { style: 'font-size: 0.75rem' }
        }"
      />
      <div class="w-full flex flex-wrap gap-1 mt-2 max-h-20 overflow-y-auto">
        <div
          v-for="(ov, i) in _filters.numOvs"
          :key="i"
        >
          <div class="bg-blue-200 p-1 flex items-center w-full rounded-md text-blue-800 text-xs">
            <button @click="eliminarOv(ov)" class="text-blue-800 mr-2">
              <i style="font-size: 0.6rem" class="pi pi-times"></i>
            </button>
            <p>{{ ov }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Numero de facturas -->
    <div class="col-span-12 sm:col-span-6">
      <label for="numFacturas" class="block text-xs font-medium text-gray-700 mb-2">Número de facturas:</label>
      <InputText
        id="numFacturas"
        class="w-full text-xs"
        @keydown="validarFacturas"
        @blur="guardarFacturas"
        @paste="validarPegado"
        v-model="facturasSeleccionadas"
        placeholder="Ingrese número de facturas... (separados por coma o por espacio)"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px; width: 100%'
          }),
          input: { style: 'font-size: 0.75rem' }
        }"
      />
      <div class="w-full flex flex-wrap gap-1 mt-2 max-h-20 overflow-y-auto">
        <div
          v-for="(factura, i) in _filters.numFacturas"
          :key="i"
        >
          <div class="bg-blue-200 p-1 flex items-center w-full rounded-md text-blue-800 text-xs">
            <button @click="eliminarFactura(factura)" class="text-blue-800 mr-2">
              <i style="font-size: 0.6rem" class="pi pi-times"></i>
            </button>
            <p>{{ factura }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Tipo fecha -->
    <div class="col-span-12 sm:col-span-2">
      <label for="tipoFecha" class="block text-xs font-medium text-gray-700 mb-2">Tipo fecha:</label>
      <Dropdown
        id="tipoFecha"
        v-model="_filters.tipoFecha"
        :options="listTipoFecha"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;'
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>
    <!-- Fecha Inicial -->
    <div class="col-span-12 sm:col-span-2">
      <label for="fechaInicial" class="block text-xs font-medium text-gray-700 mb-2">Fecha Inicial:</label>
      <InputText
        id="fechaInicial"
        type="date"
        class="w-full text-sm"
        :disabled="!_filters.tipoFecha"
        v-model="_filters.fechaInicial"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;'
          }),
        }"
      />
    </div>
    <!-- Fecha Final -->
    <div class="col-span-12 sm:col-span-2">
      <label for="fechaFinal" class="block text-xs font-medium text-gray-700 mb-2">Fecha Final:</label>
      <InputText
        id="fechaFinal"
        type="date"
        class="w-full text-sm"
        :disabled="!_filters.tipoFecha"
        v-model="_filters.fechaFinal"
        :min="_filters.fechaInicial"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;'
          }),
        }"
      />
    </div>

    <!-- Botones -->
    <div class="col-span-12 lg:col-span-12">
      <div class="flex flex-wrap justify-end items-end h-full gap-2 w-full mt-1">
        <Button
          icon="pi pi-search"
          severity="primary"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="updateFilter"
          v-tippy="{ content: 'Buscar' }"
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="resetClear"
          v-tippy="{ content: 'Limpiar Filtros' }"
        />
        <Button
          icon="pi pi-refresh text-sm"
          severity="success"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="reload()"
          v-tippy="{ content: 'Recargar' }"
        />
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>
