<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Icon from '@/components/Icon/index.vue'
import OvsFacturasCapitaValorService from '@/apps/pharmasan/facturacion/capita/services/ovsfacturasCapitaValor.service'
import ColumnTemplateDefault from '@/apps/pharmasan/facturacion/capita/views/listOvs/components/columnTemplateDefault.vue'
import { useFiltersOvsFacturaCapitaStore } from '@/apps/pharmasan/facturacion/capita/stores/filtersOvsFacturaCapita.store'
import { storeToRefs } from 'pinia'
import Filters from '@/apps/pharmasan/facturacion/capita/views/listOvs/filters/index.vue'

const route = useRoute()
const router = useRouter()
const _OvsFacturasCapitaValorService = ref(new OvsFacturasCapitaValorService())
const listOvsFacturasCapitaValor = ref([])
const filtersOvsFacturaCapitaStore = useFiltersOvsFacturaCapitaStore()
const {
  _filters
} = storeToRefs(filtersOvsFacturaCapitaStore)
const limit = ref(20)
const offset = ref(0)
const page = ref(0)
const orderField = ref('DocNumOv')
const sortOrder = ref(-1)
const listColumns = ref([])
const selectedColumns = ref([])

// Ordenar por campo
const onSort = async (event) => {
  orderField.value = event.sortField
  sortOrder.value = event.sortOrder
  await fetchListOvsFacturasCapitaValor()
}

// Paginación
const onPage = async (row) => {
  limit.value = row.rows

  await fetchListOvsFacturasCapitaValor()
}

// Params
const params = computed(() => {
  const order = `${orderField.value},${sortOrder.value}`

  return {
    offset: offset.value,
    page: page.value,
    limit: limit.value,
    order,
    filters: _filters.value
  }
})

// Función para obtener el listado de Ovs asociadas a facturas capita valor
const fetchListOvsFacturasCapitaValor = async () => {
  try {
    const { data } = await _OvsFacturasCapitaValorService.value.getListOvsFacturasCapitaValor(params.value)
    listOvsFacturasCapitaValor.value = data
    listColumns.value = data.columns
    // Agregar la columna de acciones al final
    listColumns.value.push({ field: 'actions', header: 'Acciones' })
  } catch (error) {
    console.error('Error al obtener el listado de facturas capita valor', error)
  }
}

// Funcion para exportar a excel
const exportarExcel = async () => {
  console.log('Exportar a excel')
}

// Funcion para mostrar el componente según la columna en la tabla
const showComponentColumn = (column) => {
  // Si la columna no se encuentra en el objeto, retorna el componente por defecto
  return ColumnTemplateDefault
}

// Watch para limpiar la tabla
watch(() => filtersOvsFacturaCapitaStore.getLimpiarListadoOvs, async (value) => {
  if (value) {
    filtersOvsFacturaCapitaStore.setLimpiarListadoOvs(false)
    await fetchListOvsFacturasCapitaValor()
  }
})

// Watch para refrescar la tabla
watch(() => filtersOvsFacturaCapitaStore.getRefrescarListadoOvs, async (value) => {
  if (value) {
    // Quitar de la ruta el parámetro DocNum
    _filters.value.numFacturas = []
    await router.push({ name: 'pharmasan.ventas.facturacion.capita-valor.listado-ovs' })

    offset.value = 0
    await fetchListOvsFacturasCapitaValor()
    filtersOvsFacturaCapitaStore.setRefrescarListadoOvs(false)
  }
})

// Watch si cambian los parámetros de la URL
watch(() => route.query, async () => {
  if (![
    'pharmasan.ventas.facturacion.capita-valor.carga-ov-factura',
    'pharmasan.ventas.facturacion.capita-valor.listado'
  ].includes(route.name)) {
    if (Object.keys(route.query).length === 0) {
      _filters.value.numFacturas = []
    }
    await fetchListOvsFacturasCapitaValor()
  }
})

// Hook
onMounted(async () => {
  _filters.value.numFacturas = []

  const { DocNum } = route.params
  if (DocNum) {
    _filters.value.numFacturas.push(DocNum)
  }

  await fetchListOvsFacturasCapitaValor()

  // Seleccionar las columnas por defecto
  selectedColumns.value = listColumns.value
})
</script>

<template>
  <Toolbar
    class="box my-4 py-2 px-4 flex flex-col sm:flex-row flex-wrap gap-2"
    :pt="{
      end: { class: 'w-full sm:w-auto'}
    }"
  >
    <template #start>
      <h2 class="text-lg font-bold truncate mr-5">Facturas Capita</h2>
    </template>
    <template #end>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-x-4">
    <div class="col-span-12 sm:col-span-12">

      <div class="box intro-x h-full">
        <div class="mt-0">
          <!-- Titulo Card y botones de acción -->
          <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
            <h3 class="mr-auto capitalize text-base font-medium">Listado OVs asociadas a factura</h3>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-0">
              <!-- Botón exportar a excel -->
              <Button
                icon="pi pi-external-link"
                size="small"
                severity="secondary"
                label="Exportar a excel"
                @click="exportarExcel"
                style="height: 30px"
              />
            </div>
          </div>

          <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 p-4">
            <!-- Filtros -->
            <div class="col-span-1 sm:col-span-12 md:col-span-12">
              <Filters />
            </div>

            <!-- Tabla -->
            <div class="col-span-1 sm:col-span-12 md:col-span-12 mt-4">
              <DataTable
                ref="dt"
                :value="listOvsFacturasCapitaValor.result"
                tableStyle="min-width: 50rem"
                class="p-datatable-sm text-xs"
                dataKey="Id"
                scrollable
                scrollHeight="700px"
                @sort="onSort"
                :sortField="orderField"
                :sortOrder="sortOrder"
              >
                <template #empty>
                  <div class="flex flex-col items-center justify-center" style="height: 650px">
                    <Icon icon="mdi:invoice-text-fast-outline" class="text-gray-200" style="font-size: 15rem;"/>
                    <span class="text-gray-400 text-2xl">No se encontraron registros</span>
                    <span class="text-gray-300">No hemos encontrado ningunas OVs, pruebe con otro filtro u opción</span>
                  </div>
                </template>
                <template #loading> Cargando la información, por favor espere... </template>
                <Column
                  v-for="column in selectedColumns"
                  :key="column.field"
                  :field="column.field"
                  :header="column.header"
                  :sortable="true"
                >
                  <template #body="{data}">
                    <component
                      :is="showComponentColumn(column)"
                      :data="data"
                      :column="column.field"
                    />
                  </template>
                </Column>
              </DataTable>
              <!-- Paginación -->
              <Paginator
                v-model:first="offset"
                :rows="limit"
                :totalRecords="parseInt(listOvsFacturasCapitaValor.total)"
                :rowsPerPageOptions="[5,10,20,30,100, listOvsFacturasCapitaValor.total]"
                template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
                @page="onPage($event)"
                :pt="{
                  rowPerPageDropdown: {
                    input: {
                      style: 'padding: 5px'
                    },
                  }
                }"
              >
                <template #start>
                  <div class="flex flex-wrap items-center">
                    <span class="text-xs font-bold">Total registros:</span>
                    <span class="text-xs ml-2 text-green-400">{{ listOvsFacturasCapitaValor.total }}</span>
                  </div>
                </template>
                <template #end>
                  <div class="flex flex-wrap items-center">
                    <span class="text-xs font-bold">Registros por página:</span>
                    <span class="text-xs ml-2 text-green-400">{{ limit }}</span>
                  </div>
                </template>
              </Paginator>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>

</style>
