import { defineStore } from 'pinia'
// import dayjs from 'dayjs'

export const useFiltersOvsFacturaCapitaStore = defineStore({
  id: 'filtersOvsFacturaCapita',
  state: () => ({
    _filters: {
      tipoFecha: '',
      fechaInicial: '',
      fechaFinal: '',
      numOvs: [],
      numFacturas: []
    },
    _refrescarListadoOvs: false,
    _limpiarListadoOvs: false
  }),
  getters: {
    getFilters: (state) => state._filters,
    getRefrescarListadoOvs: (state) => state._refrescarListadoOvs,
    getLimpiarListadoOvs: (state) => state._limpiarListadoOvs
  },
  actions: {
    setUpdateFilters (filters) {
      this._filters = filters
    },
    setConfig (config) {
      this._config = config
    },
    setRefrescarListadoOvs (payload) {
      this._refrescarListadoOvs = payload
    },
    setLimpiarListadoOvs (payload) {
      this._limpiarListadoOvs = payload
    }
  }
})
